export default {
  title: 'PyCon mini Hiroshima 2020',
  description: 'A site for PyCon mini Hiroshima 2020',
  openGraph: {
    url: 'https://hiroshima.pycon.jp/2020/',
    title: 'PyCon mini Hiroshima 2020',
    description: '2020年10月10日(土)に開催される PyCon mini Hiroshima 2020 のイベントのサイトです。',
    locale: 'ja_JP',
    images: [
      {
        url: 'https://hiroshima.pycon.jp/2020/static/images/ogimage.png',
        width: 1200,
        height: 630,
        alt: 'PyCon mini Hiroshima 2020',
      },
    ]
  },
  twitter: {
    handle: '@PyConHiro',
    site: '@PyConHiro',
    cardType: 'summary_large_image',
  },
};
